import React from 'react'
import { FooterTop } from './FooterTop'
import { FooterBottom } from './FooterBottom'

function Footer() {
  return (
    <div>
      <FooterTop/>
      <FooterBottom/>
    </div>
  )
}

export default Footer