import React from 'react'

import { Navbar1 } from './Navbar1'
import { Navbar2 } from './Navbar2'

function Header() {
  return (
    <div>
      <Navbar1/>
      <Navbar2/>
    </div>
  )
}

export default Header